import gql from 'graphql-tag'


const orderChildResponse = `
  id type name parentId
  docId doc {id type code name approvedAt closedAt}
  unitId unit {id name}
  inventoryId inventory {id type code name}
  qty price totalPrice
  stocks {
    id qty parentId
    inventoryId inventory {
      id type code name
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id amount stockId
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  },
  children {
    id type
    docId doc {id type code name approvedAt closedAt}
  }
`

const orderResponse = `
  id type name parentId
  docId doc {id type code name}
  unitId unit {id name}
  inventoryId inventory {id type code name}
  qty price totalPrice
  stocks {id}
  ledgers {
    id amount stockId
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  children {${orderChildResponse}}
  externalDocId externalDoc {id type code}
  withholdingType withholdingTopic
  withholdingName withholdingRemark
  withholdingRate withholdingPrice
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const WATCH_ORDER_CREATED = (templateType) => gql`subscription WATCH_ORDER_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderCreated (docType: $docType, docId: $docId) {id}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {id}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {id}
}`

export const WATCH_ORDER_REFETCH = (templateType) => gql`subscription WATCH_ORDER_REFETCH ($docType: String!, $docId: Int!) {
  orderRefetch: watchDoc${templateType}OrderRefetch (docType: $docType, docId: $docId) {id type}
}`

export const CREATE_ORDER_WITHHOLDING_PRICE = (templateType) => gql`mutation CREATE_ORDER_WITHHOLDING_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderWithholdingPriceInput!) {
  order: createDoc${templateType}OrderWithholdingPrice (docType: $docType, docId: $docId, input: $input) {
    id docId parentId
  }
}`

export const LIST_ORDER_CONFIG = (templateType) => gql`query LIST_ORDER_CONFIG ($docType: String!, $contactId: Int!) {
  configs: listDoc${templateType}OrderConfig (docType: $docType, contactId: $contactId) {
    id type name isDefault
    options
  }
}`