import gql from 'graphql-tag'


export const LIST_REPORT_DELIVERY_BY_INTERVAL = (templateType) => gql`query LIST_REPORT_DELIVERY_BY_INTERVAL ($docType: String!, $startDate: DateTime, $endDate: DateTime, $startCode: String, $endCode: String, $contactId: Int, $salesId: Int) {
  docs: listDoc${templateType}ReportDeliveryByInterval (docType: $docType, startDate: $startDate, endDate: $endDate, startCode: $startCode, endCode: $endCode, contactId: $contactId, salesId: $salesId) {
    id code closedAt
    contactId contact {id type code name}
    totalPrice vatPrice netPrice
    discount dueAt salesCredit
    salesorders billing sales
    orders {
      id type name qty totalPrice
      unit {id name}
      inventory {id code name barcode}
    }
  }
}`

export const SYNC_REPORT_DELIVERY_BY_INTERVAL = (templateType) => gql`mutation SYNC_REPORT_DELIVERY_BY_INTERVAL ($docType: String!, $month: String!) {
  result: syncDoc${templateType}ReportDeliveryByInterval (docType: $docType, month: $month)
}`


export const LIST_REPORT_INVENTORY_CONFIG = (templateType) => gql`query LIST_REPORT_INVENTORY_CONFIG ($docType: String!) {
  items: listDoc${templateType}ReportInventoryConfig (docType: $docType) {
    id type docType name
  }
}`

export const LIST_REPORT_INVENTORY_BY_INTERVAL = (templateType) => gql`query LIST_REPORT_INVENTORY_BY_INTERVAL ($docType: String!, $startDate: DateTime!, $endDate: DateTime!, $reportConfigId: Int!, $contactId: Int, $search: String) {
  groups: listDoc${templateType}ReportInventoryByInterval (docType: $docType, startDate: $startDate, endDate: $endDate, contactId: $contactId, reportConfigId: $reportConfigId, search: $search) {
    id order name regexExp totalPrice
    inventories {
      id type code name unit qty totalPrice
    }
  }
}`

export const LIST_REPORT_INVENTORY_GRAPH = (templateType) => gql`query LIST_REPORT_INVENTORY_GRAPH ($docType: String!, $startDate: DateTime!, $endDate: DateTime!, $search: String) {
  rows: listDoc${templateType}ReportInventoryGraph (docType: $docType, startDate: $startDate, endDate: $endDate, search: $search) {
    id code name total unit month year
  }
}`

export const LIST_REPORT_DELIVERY_BY_VEHICLE = (templateType) => gql`query LIST_REPORT_DELIVERY_BY_VEHICLE ($docType: String!, $startDate: DateTime!, $endDate: DateTime!, $vehicleIds: [Int]!) {
  contacts: listDoc${templateType}ReportDeliveryByVehicle (docType: $docType, startDate: $startDate, endDate: $endDate, vehicleIds: $vehicleIds) {
    date
    contacts {id type code name}
    invoices {id type code name}
  }
}`


export const LIST_SALES = (templateType) => gql`query LIST_SALES ($docType: String!) {
  contacts: listDoc${templateType}Sales (docType: $docType) {
    id type code name
  }
}`

export const LIST_REPORT_INVOICE_GLOABAL_HOUSE_BY_INTERVAL = (templateType) => gql`query LIST_REPORT_INVOICE_GLOABAL_HOUSE_BY_INTERVAL ($docType: String!, $startDate: DateTime, $endDate: DateTime, $startCode: String, $endCode: String, $contactId: Int) {
  orders: listDoc${templateType}ReportInvoiceGlobalHouseByInterval (docType: $docType, startDate: $startDate, endDate: $endDate, startCode: $startCode, endCode: $endCode, contactId: $contactId) {
    id purchaseorderRef
    barcode inventoryCode
    docCode approvedAt
    qty price vatPrice branch
  }
}`
