<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="reportFilter.startDate"
        label="วันที่เริ่ม"
        isNull>
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="reportFilter.endDate"
        label="วันที่ถึง"
        isNull>
      </sgv-input-date>

      <sgv-input-text
        class="col-6"
        v-model="reportFilter.startCode"
        label="รหัสเริ่ม">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        v-model="reportFilter.endCode"
        label="รหัสถึง">
      </sgv-input-text>

      <ListReportDeliveryContactInput
        class="col-12 col-sm-6"
        select="id"
        label="ลูกค้า"
        v-model="reportFilter.contactId"
        :docType="docType"
        :templateType="templateType">
      </ListReportDeliveryContactInput>

      <ListReportDeliverySalesInput
        class="col-12 col-sm-6"
        select="id"
        label="พนักงานขาย"
        placeholder="ทั้งหมด"
        v-model="reportFilter.salesId"
        :docType="docType"
        :templateType="templateType">
      </ListReportDeliverySalesInput>
    </div>

    <ListReportDeliveryPrinter
      class=""
      :docType="docType"
      :templateType="templateType"
      @search="searchData"
      @print="printData"
      @download="downloadData">
    </ListReportDeliveryPrinter>

    <div class="mt-3" v-if="items.length > 0">
      <sgv-csv
        :items="csvData"
        :labels="csvLabels"
        :filename="csvFilename">
        <button
          type="button"
          class="btn btn-success">
          Excel
        </button>
      </sgv-csv>
    </div>

    <div class="form-row mt-3">
      <sgv-input-month
        class="col-6"
        v-model="syncFilter.month"
        label="เดือน"
        isNull
        :validations="[
          {text: 'required!', value: $v.syncFilter.month.$dirty && $v.syncFilter.month.$error}
        ]">
      </sgv-input-month>
    </div>

    <button class="btn btn-info" @click="syncReport">
      Sync
    </button>

    <div class="table-responsive mt-3" v-if="items">
      <table class="table">
        <thead>
          <tr>
            <td colspan="4">
              {{summary.count}} รายการ |
              มูลค่าสินค้า <span class="text-primary">{{summary.totalPrice}}</span> บาท |
              VAT <span class="text-warning">{{summary.vatPrice}}</span> บาท |
              รวมทั้งสิ้น <span class="text-success">{{summary.netPrice}}</span> บาท
            </td>
          </tr>
          <tr>
            <th>เลขที่</th>
            <th>ลูกค้า</th>
            <th class="text-center">วันที่</th>
            <th class="text-right">มูลค่า</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i">
            <td>
              <small>
                <div class="">
                  {{item.code}}
                </div>
                <div
                  class="text-primary"
                  v-for="so in item.salesorders"
                  :key="so">
                  <small>{{so}}</small>
                </div>
              </small>
            </td>
            <td>
              <small>
                <div class="">
                  <fa icon="building" class="text-primary"></fa>
                  <span class="ml-1">{{item.contact.name}}</span>
                </div>
                <div
                  v-for="sale in item.sales"
                  :key="sale">
                  <fa icon="user-tie" class="text-success"></fa>
                  <span class="ml-1">{{sale}}</span>
                </div>
              </small>
            </td>
            <td class="text-center">
              <small>
                <div v-if="item.dueAt" class="text-danger">
                  <fa icon="clock"></fa>
                  <span class="ml-1">
                    {{$date.format(item.dueAt).displayThai}}
                  </span>
                </div>
                <div class="text-success">
                  <fa icon="truck"></fa>
                  <span class="ml-1">
                    {{$date.format(item.closedAt).displayThai}}
                  </span>
                </div>
              </small>
            </td>
            <td class="text-right">
              <small>
                <div class="">
                  <span class="text-info mr-1">
                    {{item.discount.join(', ')}}
                  </span>
                  {{$form.addComma(item.totalPrice)}}
                </div>
                <div class="text-warning">{{$form.addComma(item.vatPrice)}}</div>
                <div class="text-success">{{$form.addComma(item.netPrice)}}</div>
              </small>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_REPORT_DELIVERY_BY_INTERVAL,
  SYNC_REPORT_DELIVERY_BY_INTERVAL
} from './graph'
import ListReportDeliveryContactInput from './ListReportDeliveryContactInput.vue'
import ListReportDeliverySalesInput from './ListReportDeliverySalesInput.vue'
import ListReportDeliveryPrinter from './ListReportDeliveryPrinter.vue'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}ReportDeliveryByInvoice`,
      items: [],
      reportFilter: {
        startDate: '',
        endDate: '',
        startCode: '',
        endCode: '',
        contactId: null,
        salesId: null,
      },
      syncFilter: {
        month: '',
      },
      rFields: ['reportFilter', 'syncFilter'],
      url: `/doc/${this.docType}/report/delivery/invoice/print`
    }
  },
  validations: {
    syncFilter: {
      month: {required}
    }
  },
  computed: {
    summary () {
      const count = this.items.length
      const totalPrice = this.items.reduce((t,v) => t += +v.totalPrice, 0)
      const vatPrice = this.items.reduce((t,v) => t += +v.vatPrice, 0)
      const netPrice = this.items.reduce((t,v) => t += +v.netPrice, 0)
      return {
        count,
        totalPrice: this.$form.addComma(totalPrice),
        vatPrice: this.$form.addComma(vatPrice),
        netPrice: this.$form.addComma(netPrice)
      }
    },
    csvLabels () {
      return {
        docCode: { title: 'เลขที่ใบกำกับภาษี' },
        closedAt: { title: 'วันที่ใบกำกับภาษี' },
        barcode: { title: 'รหัสสินค้า(โกลบอลเฮ้าส์)' },
        inventoryCode: { title: 'รหัสสินค้า(ผุ้จำหน่าย)' },
        orderName: { title: 'ชื่อสินค้า' },
        orderQty: { title: 'จำนวน' },
        orderTotalPrice: { title: 'มูลค่า(ไม่รวมภาษีมูลค่าเพิ่ม)' },
      }
    },
    csvData () {
      return this.items.flatMap(d => {
        return d.orders
        .filter(o => o.type === 'item')
        .map(o => {
          return {
            docCode: d.code,
            closedAt: this.$date.format(d.closedAt).displayThai,
            barcode: o.inventory.barcode || '',
            inventoryCode: o.inventory.code,
            orderName: o.name,
            orderQty: this.$form.addComma(o.qty, -1),
            orderTotalPrice: this.$form.addComma(o.totalPrice, 2),
          }
        })
      })
    },
    csvFilename () {
      return 'รายงานจัดส่ง'
    }
  },
  methods: {
    serializeInput () {
      const obj = {}
      if (this.reportFilter.startDate) obj.startDate = this.$date.format(this.reportFilter.startDate).utc
      if (this.reportFilter.endDate) obj.endDate = this.$date.format(this.reportFilter.endDate, 0, 1).utc
      if (this.reportFilter.startCode) obj.startCode = this.reportFilter.startCode
      if (this.reportFilter.endCode) obj.endCode = this.reportFilter.endCode
      if (this.reportFilter.contactId) obj.contactId = this.reportFilter.contactId
      if (this.reportFilter.salesId) obj.salesId = this.reportFilter.salesId

      return obj
    },
    searchData () {
      this.setRetaining()
      this.items = []

      const input = this.serializeInput()

      this.$apollo.query({
        query: LIST_REPORT_DELIVERY_BY_INTERVAL(this.templateType),
        variables: {
          docType: this.docType,
          ...input
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.docs
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    downloadData () {
      this.$axios.get(this.url, {
        params: this.serializeInput(),
        responseType: 'blob'
      })
      .then(res => {
        let filename = 'invoice_report.pdf'
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
      }).catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
    printData (deviceId) {
      if (!deviceId) {
        this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
        return
      }

      this.$axios.get(this.url, {
        params: {
          ...this.serializeInput(),
          deviceId
        }
      })
      .then(() => {
        this.$toasted.global.success("ส่งไปยังเครื่องพิมพ์")
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถพิมพ์หรือบันทึกไฟล์ได้")
      })
    },
    syncReport () {
      this.$v.syncFilter.$touch()
      if (this.$v.syncFilter.$invalid) return

      this.setRetaining()

      this.$apollo.mutate({
        mutation: SYNC_REPORT_DELIVERY_BY_INTERVAL(this.templateType),
        variables: {
          docType: this.docType,
          month: this.syncFilter.month
        },
        fetchPolicy: 'no-cache'
      })
      .then(() => {
        this.$toasted.global.success('อัพเดทสำเร็จ')
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
  },
  watch: {
    reportFilter: {
      handler () {
        this.setRetaining()
      },
      deep: true
    }
  },
  components: {
    ListReportDeliveryContactInput,
    ListReportDeliverySalesInput,
    ListReportDeliveryPrinter
  }
}
</script>

<style lang="css" scoped>
</style>
