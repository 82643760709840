<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="reportFilter.startDate"
        label="วันที่เริ่ม"
        isNull>
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="reportFilter.endDate"
        label="วันที่ถึง"
        isNull>
      </sgv-input-date>

      <sgv-input-text
        class="col-6"
        v-model="reportFilter.startCode"
        label="รหัสเริ่ม">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        v-model="reportFilter.endCode"
        label="รหัสถึง">
      </sgv-input-text>

      <ListReportDeliveryContactInput
        class="col-12 col-sm-6"
        select="id"
        label="ลูกค้า"
        v-model="reportFilter.contactId"
        :docType="docType"
        :templateType="templateType">
      </ListReportDeliveryContactInput>
    </div>

    <button
      class="btn btn-warning mr-2"
      @click="searchData">
      <fa icon="search"></fa>
      <span class="ml-1">ค้นหา</span>
    </button>

    <sgv-csv
      v-if="items.length > 0"
      :items="csvData"
      :labels="csvLabels"
      :filename="csvFilename">
      <button
        type="button"
        class="btn btn-success">
        Excel
      </button>
    </sgv-csv>

    <div class="table-responsive mt-3" v-if="items">
      <table class="table">
        <thead>
          <tr>
            <th>เลขที่ PO</th>
            <th>รหัสสินค้า</th>
            <th>จำนวน</th>
            <th>ใบกำกับภาษี</th>
            <th>ราคาต่อหน่วย</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i">
            <td>
              {{item.purchaseorderRef}}
              <div class="">
                <small class="text-info">
                  {{item.branch}}
                </small>
              </div>
            </td>
            <td>
              {{item.barcode}}
              <div class="">
                <small class="text-primary">
                  {{item.inventoryCode}}
                </small>
              </div>
            </td>
            <td>{{item.qty}}</td>
            <td>
              {{item.docCode}}
              <small>
                <div class="text-warning">
                  {{item.approvedAt}}
                </div>
              </small>
            </td>
            <td class="text-right">
              {{item.price | comma}}
              <div class="">
                <small class="text-success">
                  {{item.vatPrice | comma}}
                </small>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import { LIST_REPORT_INVOICE_GLOABAL_HOUSE_BY_INTERVAL } from './graph'
import ListReportDeliveryContactInput from './ListReportDeliveryContactInput.vue'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}ReportInvoiceGloabalHouse`,
      items: [],
      reportFilter: {
        startDate: '',
        endDate: '',
        startCode: '',
        endCode: '',
        contactId: null,
      },
      syncFilter: {
        month: '',
      },
      rFields: ['reportFilter', 'syncFilter']
    }
  },
  validations: {
    syncFilter: {
      month: {required}
    }
  },
  computed: {
    csvLabels () {
      return {
        purchaseorderRef: { title: 'เลขที่เอกสาร PO' },
        barcode: { title: 'รหัสสินค้า' },
        qty: { title: 'จำนวนสินค้า' },
        docCode: { title: 'เลขที่ใบกำกับภาษี' },
        approvedAt: { title: 'วันที่ใบกำกับภาษี' },
        discountPrice: { title: 'ราคาหลังหักส่วนลดรายตัว / หน่วย (EX VAT) ถ้ามี' },
        price: { title: 'ราคาต่อหน่วย PO (EX VAT)' },
        vatPrice: { title: 'ราคาต่อหน่วย PO (IN VAT)' },
        branch: { title: 'ชื่อสาขา' },
      }
    },
    csvData () {
      return this.items
    },
    csvFilename () {
      return 'รายงานจัดส่ง_GlobalHouse'
    }
  },
  methods: {
    serializeInput () {
      const obj = {}
      if (this.reportFilter.startDate) obj.startDate = this.$date.format(this.reportFilter.startDate).utc
      if (this.reportFilter.endDate) obj.endDate = this.$date.format(this.reportFilter.endDate, 0, 1).utc
      if (this.reportFilter.startCode) obj.startCode = this.reportFilter.startCode
      if (this.reportFilter.endCode) obj.endCode = this.reportFilter.endCode
      if (this.reportFilter.contactId) obj.contactId = this.reportFilter.contactId

      return obj
    },
    serializeData (orders) {
      return orders.map(o => {
        return {
          purchaseorderRef: o.purchaseorderRef,
          barcode: o.barcode,
          inventoryCode: o.inventoryCode,
          qty: o.qty,
          docCode: o.docCode,
          approvedAt: this.$date.format(o.approvedAt).date,
          discountPrice: 0,
          price: this.$form.addComma(o.price),
          vatPrice: this.$form.addComma(o.vatPrice),
          branch: o.branch
        }
      })
    },
    searchData () {
      this.setRetaining()
      this.items = []

      const input = this.serializeInput()

      this.$apollo.query({
        query: LIST_REPORT_INVOICE_GLOABAL_HOUSE_BY_INTERVAL(this.templateType),
        variables: {
          docType: this.docType,
          ...input
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = this.serializeData(res.data.orders)
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
  },
  watch: {
    reportFilter: {
      handler () {
        this.setRetaining()
      },
      deep: true
    }
  },
  components: {
    ListReportDeliveryContactInput
  }
}
</script>

<style lang="css" scoped>
</style>
