<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6 col-md-4"
        v-model="startDate"
        label="วันที่เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-6 col-md-4"
        v-model="endDate"
        label="วันที่ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-text
        class="col-12 col-md-4"
        v-model="search"
        label="ตัวกรอง"
        @keyup.enter="fetchData"
        :validations="[
          {text: 'required!', value: $v.search.$dirty && $v.search.$error}
        ]">
      </sgv-input-text>
    </div>

    <button class="btn btn-warning mb-3" @click="fetchData">
      ค้นหา
    </button>

    <div class="row">
      <div class="col-12 mb-3" v-for="(item, i) in items" :key="item.id">
        <canvas :id="'planet-chart-' + i"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import Chart from 'chart.js/auto'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import { required } from 'vuelidate/lib/validators'
import { LIST_REPORT_INVENTORY_GRAPH } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      items: [],
      startDate: '',
      endDate: '',
      search: '',
      rKey: `Doc${this.$form.capitalize(this.docType)}ReportInventoryGraph`,
      rFields: ['search', 'startDate', 'endDate'],
      months: [
        'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน',
        'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม',
        'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
      ],
    }
  },
  validations: {
    startDate: {required},
    endDate: {required},
    search: {required},
  },
  computed: {
    lineColors () {
      const rows = this.items.reduce((t, v) => {
        return [...t, ...v.rows]
      }, [])
      const years = uniq(rows.map(v => v.year))
      return years.map(v => ({year: v, color: this.randomColor()}))
    }
  },
  methods: {
    randomColor () {
      const o = Math.round
      const r = Math.random
      const s = 255
      return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + '1' + ')'
    },
    mapItemsByInventory (items) {
      let inventories = uniqBy(items.map(v => {
        return {id: v.id, code: v.code, name: v.name, unit: v.unit}
      }), 'id')
      let arr = inventories.map(v => {
        let rows = items.filter(x => x.id === v.id).map(z => {
          return {year: z.year, month: z.month, total: Number(z.total) * -1}
        })
        return {
          id: v.id,
          code: v.code,
          name: v.name,
          unit: v.unit,
          rows
        }
      })
      return arr
    },
    mapDatasets (items) {
      let current = this.$date.current()
      let years = uniq(items.map(v => v.year))
      let datasets = years.map(v => {
        let arr = []
        let colors = []
        let color = this.lineColors.find(lc => lc.year === v)
        for (let i = 1; i < 13; i++) {
          let item = items.find(x => x.year === v && Number(x.month) === i)
          if (item) {
            arr.push(item.total)
          }
          else if (!item && !(v == current.yyyy && i > Number(current.mm))) {
            arr.push(0)
          }
          colors.push(color.color)
        }
        return {
          label: v,
          data: arr,
          borderWidth: 3,
          fill: false,
          backgroundColor: colors,
          borderColor: colors,
          tension: 0.4
        }
      })
      return datasets
    },
    createChart(chartId, item) {
      const ctx = document.getElementById(chartId)
      new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.months,
          datasets: this.mapDatasets(item.rows)
        },
        options: {
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: `${item.code} (${item.name})`
            }
          },
          scales: {
            y: {
              ticks: {
                beginAtZero: true,
                padding: 25,
              }
            }
          },
        },
      })
    },
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()
      this.items = []
      this.$apollo.query({
        query: LIST_REPORT_INVENTORY_GRAPH(this.templateType),
        variables: {
          docType: this.docType,
          startDate: this.$date.format(this.startDate).utc,
          endDate: this.$date.format(this.endDate, 0, 1).utc,
          search: this.search
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = this.mapItemsByInventory(res.data.rows)
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
  },
  watch: {
    items (value) {
      this.$nextTick(() => {
        value.forEach((v, i) => {
          this.createChart('planet-chart-' + i, v)
        })
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
