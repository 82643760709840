<template>
  <div>
    <div class="form-row">
      <ListPlanningVehicle
        label="ยานพาหนะ"
        class="col-6"
        v-model="selectedVehicle"
        :docType="docType"
        :templateType="templateType"
        select="id">

      </ListPlanningVehicle>
      <sgv-input-month
        label="เดือน"
        v-model="selectedMonth"
        class="col-6">
      </sgv-input-month>
    </div>

    <sgv-table
      :rKey="rKey"
      :items="datelist"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.date">
            {{ item.date | date }}
          </td>
          <td v-if="hidden.docs">
            <div v-for="doc in item.docs" :key="doc.id">
              <router-link
                :to="toDoc(doc)"
                class="text-decoration-none">
                <span
                  style="white-space: nowrap;"
                  :class="getStatus(doc)">
                  {{doc.code}}
                </span>
              </router-link>
              | {{doc.contact.code}} ({{doc.contact.name}})
              |
              <ListPlanningDeliveryLocation
                :title="doc.code"
                :templateType="templateType"
                :docId="doc.id"
                :docType="docType">
              </ListPlanningDeliveryLocation>
              <small>
                <div v-if="doc.remark" class="text-primary">
                  {{doc.remark}}
                </div>
                <div v-if="doc.logistic" class="text-info">
                  <fa icon="truck"></fa>
                  {{doc.logistic.name}}
                </div>
              </small>
            </div>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_PLANNING } from './graph'
import ListPlanningVehicle from './ListPlanningVehicle'
import ListPlanningDeliveryLocation from './ListPlanningDeliveryLocation'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      headers: [
        {text: 'วันที่', value: 'date', sort: false, filter: false},
        {text: 'เอกสาร', value: 'docs', sort: false, filter: false}
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['date', 'docs'],
        column: null,
        search: null,
        toolbar: null,
      },
      rKey: `Doc${this.$form.capitalize(this.docType)}ListPlanning`,
      rFields: ['options', 'selectedMonth', 'selectedVehicle'],
      selectedMonth: '',
      selectedVehicle: null,
      items: [],
      docs: []
    }
  },
  computed: {
    datelist () {
      if (!this.selectedMonth) return []
      const d = new Date(this.selectedMonth + '-01')
      const arr = this.$date.serial(this.$date.format(d).startOfMonth, this.$date.format(d).endOfMonth)
      return arr.map(v => {
        const found = this.items.filter(x => {
          const approvedDate = this.$date.format(x.approvedAt).date
          return approvedDate === v
        }).sort((a, b) => new Date(a.approvedAt) - new Date(b.approvedAt))
        return {date: v, docs: found}
      })
    },
  },
  apollo: {
    docs: {
      query () {
        return LIST_PLANNING(this.templateType)
      },
      variables () {
        this.setRetaining()
        return {
          docType: this.docType,
          month: this.selectedMonth + '-01',
          vehicleId: this.selectedVehicle
        }
      },
      skip () {
        return !this.selectedMonth
      },
      fetchPolicy: 'network-only',
      debounce: 150,
      result (res) {
        this.items = res.data.docs.map(v => ({
          ...v,
          isLink: this.$auth.hasRole(`doc:${v.type}:read`)
        }))
      }
    }
  },
  methods: {
    toDoc (item) {
      if (this.$auth.hasRole(`doc:${this.docType}:read`)) {
        return {
          name: `Doc${this.$form.capitalize(item.type)}Detail`,
          params: {docId: item.id},
        }
      }
      return ''
    },
    getStatus (item) {
      if (item.approvedAt && !item.closedAt) return ['text-warning']
      else if (item.closedAt) return ['text-success']
    }
  },
  components: {
    ListPlanningVehicle,
    ListPlanningDeliveryLocation
  }
}
</script>
