import gql from 'graphql-tag'


export const LIST_KPI = (templateType) => gql`query LIST_KPI ($docType: String!, $docId: Int!) {
  doc: detailDoc${templateType} (docType: $docType, docId: $docId) {
    id
    kpiTemplate {
      id code name type
      children {
        id code name
        children {
          id code name formType
          actualPoint minPoint maxPoint
          choices {
            text value
          }
        }
      }
    }
    kpis {
      id type code name remark
      actualPoint minPoint maxPoint
    }
  }
}`

export const UPDATE_KPI = (templateType) => gql`mutation UPDATE_KPI ($docType: String!, $docId: Int!, $kpis: [Doc${templateType}KpiInput]!) {
  updateKpi: updateDoc${templateType}Kpi (docType: $docType, docId: $docId, kpis: $kpis) {
    id type code name remark
    actualPoint minPoint maxPoint
  }
}`
