<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="reportFilter.startDate"
        label="วันที่เริ่ม"
        isNull>
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="reportFilter.endDate"
        label="วันที่ถึง"
        isNull>
      </sgv-input-date>

      <sgv-input-text
        class="col-6"
        v-model="reportFilter.startCode"
        label="รหัสเริ่ม">
      </sgv-input-text>

      <sgv-input-text
        class="col-6"
        v-model="reportFilter.endCode"
        label="รหัสถึง">
      </sgv-input-text>

      <ListReportDeliveryContactInput
        class="col-12 col-sm-6"
        select="id"
        label="ลูกค้า"
        v-model="reportFilter.contactId"
        :docType="docType"
        :templateType="templateType">
      </ListReportDeliveryContactInput>

      <ListReportDeliverySalesInput
        class="col-12 col-sm-6"
        select="id"
        label="พนักงานขาย"
        placeholder="ทั้งหมด"
        v-model="reportFilter.salesId"
        :docType="docType"
        :templateType="templateType">
      </ListReportDeliverySalesInput>
    </div>

    <ListReportDeliveryPrinter
      class=""
      :docType="docType"
      :templateType="templateType"
      @search="searchData"
      @print="printData"
      @download="downloadData">
    </ListReportDeliveryPrinter>

    <div class="form-row mt-3">
      <sgv-input-month
        class="col-6"
        v-model="syncFilter.month"
        label="เดือน"
        isNull
        :validations="[
          {text: 'required!', value: $v.syncFilter.month.$dirty && $v.syncFilter.month.$error}
        ]">
      </sgv-input-month>
    </div>

    <button class="btn btn-info" @click="syncReport">
      Sync
    </button>

    <div class="table-responsive mt-3" v-if="items">
      <table class="table">
        <thead>
          <tr>
            <td colspan="4">
              {{summary.count}} รายการ |
              มูลค่าสินค้า <span class="text-primary">{{summary.totalPrice}}</span> บาท |
              VAT <span class="text-warning">{{summary.vatPrice}}</span> บาท |
              รวมทั้งสิ้น <span class="text-success">{{summary.netPrice}}</span> บาท
            </td>
          </tr>
          <tr>
            <th>ลูกค้า</th>
            <th class="text-center">ยอดรวม</th>
            <th class="text-right">Vat</th>
            <th class="text-right">สุทธิ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>
              <div class="">
                {{item.code}} ({{item.name}})
              </div>
              <div
                class="text-success"
                v-for="iv in item.invoices"
                :key="iv.id">
                <small>{{iv.code}}</small>
                <small v-if="iv.salesCredit" class="text-danger">
                  ({{iv.salesCredit}})
                </small>
                <small v-if="iv.billing" class="text-info">
                  - {{iv.billing}}
                </small>
              </div>
            </td>
            <td class="text-right">
              {{item.totalPrice}}
              <div
                class=""
                v-for="iv in item.invoices"
                :key="iv.id">
                <small>{{iv.totalPrice}}</small>
              </div>
            </td>
            <td class="text-right">
              {{item.vatPrice}}
              <div
                class="text-warning"
                v-for="iv in item.invoices"
                :key="iv.id">
                <small>{{iv.vatPrice}}</small>
              </div>
            </td>
            <td class="text-right">
              {{item.netPrice}}
              <div
                class="text-success"
                v-for="iv in item.invoices"
                :key="iv.id">
                <small>{{iv.netPrice}}</small>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_REPORT_DELIVERY_BY_INTERVAL,
  SYNC_REPORT_DELIVERY_BY_INTERVAL
} from './graph'
import ListReportDeliveryContactInput from './ListReportDeliveryContactInput.vue'
import ListReportDeliverySalesInput from './ListReportDeliverySalesInput.vue'
import ListReportDeliveryPrinter from './ListReportDeliveryPrinter.vue'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}ReportDeliveryByInterval`,
      items: [],
      rows: [],
      reportFilter: {
        startDate: '',
        endDate: '',
        startCode: '',
        endCode: '',
        contactId: null,
        salesId: null,
      },
      syncFilter: {
        month: '',
      },
      rFields: ['reportFilter', 'syncFilter'],
      url: `/doc/${this.docType}/report/delivery/contact/print`
    }
  },
  validations: {
    syncFilter: {
      month: {required}
    }
  },
  computed: {
    summary () {
      const count = this.rows.length
      const totalPrice = this.rows.reduce((t,v) => t += +v.totalPrice, 0)
      const vatPrice = this.rows.reduce((t,v) => t += +v.vatPrice, 0)
      const netPrice = this.rows.reduce((t,v) => t += +v.netPrice, 0)
      return {
        count,
        totalPrice: this.$form.addComma(totalPrice),
        vatPrice: this.$form.addComma(vatPrice),
        netPrice: this.$form.addComma(netPrice)
      }
    }
  },
  methods: {
    serializeInput () {
      const obj = {}
      if (this.reportFilter.startDate) obj.startDate = this.$date.format(this.reportFilter.startDate).utc
      if (this.reportFilter.endDate) obj.endDate = this.$date.format(this.reportFilter.endDate, 0, 1).utc
      if (this.reportFilter.startCode) obj.startCode = this.reportFilter.startCode
      if (this.reportFilter.endCode) obj.endCode = this.reportFilter.endCode
      if (this.reportFilter.contactId) obj.contactId = this.reportFilter.contactId
      if (this.reportFilter.salesId) obj.salesId = this.reportFilter.salesId

      return obj
    },
    serializeData (docs) {
      const contact = {}

      docs.forEach(doc => {
        if (!contact[doc.contactId]) {
          contact[doc.contactId] = {
            id: doc.contact.id,
            code: doc.contact.code,
            name: doc.contact.name,
            totalPrice: 0,
            vatPrice: 0,
            netPrice: 0,
            invoices: []
          }
        }

        const obj = contact[doc.contactId]

        obj.totalPrice += doc.totalPrice
        obj.vatPrice += doc.vatPrice
        obj.netPrice += doc.netPrice

        obj.invoices.push({
          id: doc.id,
          code: doc.code,
          salesCredit: doc.salesCredit,
          totalPrice: this.$form.addComma(doc.totalPrice),
          vatPrice: this.$form.addComma(doc.vatPrice),
          netPrice: this.$form.addComma(doc.netPrice),
          billing: doc.billing
        })
      })

      return Object.keys(contact).map(v => {
        const obj = contact[v]
        return {
          id: obj.id,
          code: obj.code,
          name: obj.name,
          totalPrice: this.$form.addComma(obj.totalPrice),
          vatPrice: this.$form.addComma(obj.vatPrice),
          netPrice: this.$form.addComma(obj.netPrice),
          invoices: obj.invoices,
          netPriceRaw: obj.netPrice
        }
      })
      .sort((a,b) => b.netPriceRaw - a.netPriceRaw)
      // .sort((a,b) => a.code.localeCompare(b.code))
    },
    searchData () {
      this.setRetaining()
      this.items = []

      const input = this.serializeInput()

      this.$apollo.query({
        query: LIST_REPORT_DELIVERY_BY_INTERVAL(this.templateType),
        variables: {
          docType: this.docType,
          ...input
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.rows = res.data.docs
        this.items = this.serializeData(res.data.docs)
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    downloadData () {
      this.$axios.get(this.url, {
        params: this.serializeInput(),
        responseType: 'blob'
      })
      .then(res => {
        let filename = 'invoice_report.pdf'
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
      }).catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
    printData (deviceId) {
      if (!deviceId) {
        this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
        return
      }

      this.$axios.get(this.url, {
        params: {
          ...this.serializeInput(),
          deviceId
        }
      })
      .then(() => {
        this.$toasted.global.success("ส่งไปยังเครื่องพิมพ์")
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถพิมพ์หรือบันทึกไฟล์ได้")
      })
    },
    syncReport () {
      this.$v.syncFilter.$touch()
      if (this.$v.syncFilter.$invalid) return

      this.setRetaining()

      this.$apollo.mutate({
        mutation: SYNC_REPORT_DELIVERY_BY_INTERVAL(this.templateType),
        variables: {
          docType: this.docType,
          month: this.syncFilter.month
        },
        fetchPolicy: 'no-cache'
      })
      .then(() => {
        this.$toasted.global.success('อัพเดทสำเร็จ')
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
  },
  watch: {
    reportFilter: {
      handler () {
        this.setRetaining()
      },
      deep: true
    }
  },
  components: {
    ListReportDeliveryContactInput,
    ListReportDeliverySalesInput,
    ListReportDeliveryPrinter
  }
}
</script>

<style lang="css" scoped>
</style>
