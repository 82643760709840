<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="startDate"
        label="วันที่เริ่ม"
        isNull>
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="endDate"
        label="วันที่ถึง"
        isNull>
      </sgv-input-date>

      <ListReportDeliveryByVehicleInput
        class="col-12"
        select="id"
        label="ยานพาหนะ"
        v-model="vehicleIds"
        :docType="docType"
        :templateType="templateType">
      </ListReportDeliveryByVehicleInput>
    </div>

    <button
      type="button"
      class="btn btn-warning mb-3"
      @click="searchData">
      ค้นหา
    </button>

    <div class="table-responsive" v-if="items">
      <table class="table">
        <thead>
          <tr>
            <th class="text-center">วันที่</th>
            <th>ลูกค้า</th>
            <th>ใบส่งสินค้า</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i">
            <td class="text-center">
              {{item.date | date}}
            </td>
            <td>
              <div class="text-warning">
                {{item.contacts.length}} รายการ
              </div>
              <div
                v-for="contact in item.contacts"
                :key="contact.id">
                <small>{{contact.code}} ({{contact.name}})</small>
              </div>
            </td>
            <td>
              <div class="text-primary">
                {{item.invoices.length}} รายการ
              </div>
              <div
                v-for="invoice in item.invoices"
                :key="invoice.id">
                <small>{{invoice.code}}</small>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_REPORT_DELIVERY_BY_VEHICLE } from './graph'
import ListReportDeliveryByVehicleInput from './ListReportDeliveryByVehicleInput.vue'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      items: [],
      rows: [],
      startDate: '',
      endDate: '',
      startCode: '',
      endCode: '',
      vehicleIds: [],
      rKey: `Doc${this.$form.capitalize(this.docType)}ReportDeliveryByVehicle`,
      rFields: [
        'startDate', 'endDate',
        'vehicleIds'
      ]
    }
  },
  methods: {
    serializeInput () {
      const obj = {}
      if (this.startDate) obj.startDate = this.$date.format(this.startDate).utc
      if (this.endDate) obj.endDate = this.$date.format(this.endDate, 0, 1).utc
      if (this.vehicleIds) obj.vehicleIds = this.vehicleIds

      return obj
    },
    serializeData (contacts) {
      return contacts
    },
    searchData () {
      this.setRetaining()
      this.items = []

      const input = this.serializeInput()

      this.$apollo.query({
        query: LIST_REPORT_DELIVERY_BY_VEHICLE(this.templateType),
        variables: {
          docType: this.docType,
          ...input
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.rows = res.data.contacts
        this.items = this.serializeData(res.data.contacts)
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    }
  },
  watch: {
    startDate: 'setRetaining',
    endDate: 'setRetaining',
    vehicleIds: 'setRetaining',
  },
  components: {
    ListReportDeliveryByVehicleInput
  }
}
</script>

<style lang="css" scoped>
</style>
