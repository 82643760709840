<template>
  <sgv-input-autocomplete
    :search.sync="search"
    :display.sync="display"
    :options="options"
    @input="emitSelected"
    :validations="validations"
    v-bind="$attrs">

    <label slot="label">{{label}}</label>

    <template slot-scope="option">
      {{option.code}} ({{option.name}})
    </template>
  </sgv-input-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
import { LIST_CONTACT } from './graph'

export default {
  name: 'DropdownContactInput',
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    select: {
      type: String,
      required: false
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    },
  },
  data () {
    return {
      display: null,
      options: [],
      search: ''
    }
  },
  methods: {
    getDisplay (option) {
      return `${option.code} (${option.name})`
    },
    emitSelected (option) {
      this.setDisplay(option)
      this.emitInput(this.optionByKey(option))
      this.emitChange(option)
    },
    setDisplay (option) {
      if (option) this.display = this.getDisplay(option)
    },
    optionByKey (option) {
      if (!option) return null
      if (!this.select) return option
      return option[this.select]
    },
    emitInput (value) {
      this.$emit('input', value)
    },
    emitChange (value) {
      this.$emit('change', value)
    },
    refetch: debounce(function() {
      this.$apollo.query({
        query: LIST_CONTACT(this.templateType),
        variables: {
          docType: this.docType,
          q: {
            limit: 10,
            params: {
              search: this.search
            }
          }
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.options = res.data.contacts
      })
      .catch(err => {
        this.$toasted.global.error(err)
        this.setDefault()
      })
    }, 300),
    fetchData (id) {
      this.$apollo.query({
        query: LIST_CONTACT(this.templateType),
        variables: {
          docType: this.docType,
          q: {
            limit: 1,
            params: {id}
          }
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const contacts = res.data.contacts
        if (contacts.length === 0) {
          this.$toasted.global.error('ไม่พบข้อมูล')
        }
        this.options = contacts
        this.setDisplay(contacts[0])
        this.emitInput(this.optionByKey(contacts[0]))
        this.emitChange(contacts[0])
      })
      .catch(err => {
        this.$toasted.global.error(err)
        this.setDefault()
      })
    },
    setDefault () {
      this.options = []
      this.emitInput(null)
      this.emitChange(null)
    }
  },
  watch: {
    search () {
      this.refetch()
    },
    value: {
      handler (v) {
        if (!v) {
          this.setDefault()
        } else {
          this.fetchData(v)
        }
      },
      immediate: true
    }
  },
}
</script>

<style lang="css">
</style>
