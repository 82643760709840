<template>
  <sgv-input-check-array
    :label="label"
    :disabled="disabled"
    :options="items"
    inline
    :value="value"
    select="id"
    @input="emitInput">
    <template slot-scope="option">
      <span :class="{'text-success': value.find(v => v.id === option.id)}">
        {{option.code}} ({{option.name}})
      </span>
    </template>
  </sgv-input-check-array>
</template>

<script>
import { LIST_VEHICLE } from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      items: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_VEHICLE(this.templateType)
      },
      variables () {
        return {docType: this.docType}
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    emitInput (v) {
      this.$emit('input', v)
    }
  }
}
</script>

<style lang="css">
</style>
