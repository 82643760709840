<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-4"
        v-model="startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-4"
        v-model="endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-text
        class="col-4"
        v-model="search"
        label="ตัวกรอง">
      </sgv-input-text>

      <ListReportInventoryReportConfigInput
        class="col-12 col-sm-6"
        select="id"
        label="รายงาน"
        placeholder="เลือกกลุ่มรายงาน..."
        v-model="reportConfigId"
        :docType="docType"
        :templateType="templateType"
        :validations="[
          {text: 'required!', value: $v.reportConfigId.$dirty && $v.reportConfigId.$error}
        ]">
      </ListReportInventoryReportConfigInput>

      <ListReportInventoryContactInput
        class="col-12 col-sm-6"
        select="id"
        label="ลูกค้า"
        v-model="contactId"
        :docType="docType"
        :templateType="templateType">
      </ListReportInventoryContactInput>
    </div>

    <button class="btn btn-warning mb-3" @click="fetchData">
      ค้นหา
    </button>

    <div class="table-responsive" v-if="groups.length > 0">
      <table class="table">
        <thead>
          <tr>
            <th>สินค้า</th>
            <th>จำนวน</th>
            <th>มูลค่า</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-info">
            <td>ยอดรวมทั้งหมด</td>
            <td>
              <div
                v-for="(qty, unit) in qtyByGroup(groups.flatMap(g => g.inventories))"
                :key="unit">
                <span :class="{'text-success': qty > 0, 'text-danger':qty < 0}">
                  <span class="text-danger">{{qty | comma(-1)}}</span>
                </span>
                {{unit}}
              </div>
            </td>
            <td>{{totalPrice | comma}}</td>
          </tr>
          <template v-for="(group, i) in groups">
            <tr
              class="pointer"
              :key="`g-${i}`"
              @click="group.isDetail = !group.isDetail">
              <td>
                <span class="text-success">{{group.name}}</span>
              </td>
              <td>
                <div
                  v-for="(qty, unit) in qtyByGroup(group.inventories)"
                  :key="unit">
                  <span :class="{'text-success': qty > 0, 'text-danger':qty < 0}">
                    <span class="text-danger">{{qty | comma(-1)}}</span>
                  </span>
                  {{unit}}
                </div>
              </td>
              <td>
                <span class="text-success">{{group.totalPrice | comma}}</span>
              </td>
            </tr>

            <template v-if="group.isDetail">
              <tr v-for="(item,idx) in group.inventories" :key="item.id">
                <td>
                  {{idx+1}}. {{item.code}}<br>
                  <small class="text-primary">{{item.name}}</small>
                </td>
                <td>
                  <span :class="{'text-success': item.total > 0, 'text-danger': item.total < 0}">
                    <span class="text-danger">{{item.qty | comma(-1)}}</span>
                  </span>
                  {{item.unit}}
                </td>
                <td>
                  {{item.totalPrice | comma}}
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import { LIST_REPORT_INVENTORY_BY_INTERVAL } from './graph'
import ListReportInventoryContactInput from './ListReportInventoryContactInput.vue'
import ListReportInventoryReportConfigInput from './ListReportInventoryReportConfigInput.vue'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      groups: [],
      startDate: '',
      endDate: '',
      contactId: null,
      search: '',
      reportConfigId: null,
      rKey: `Doc${this.$form.capitalize(this.docType)}ReportInventoryByInterval`,
      rFields: ['startDate', 'endDate', 'contactId', 'reportConfigId'],
    }
  },
  validations: {
    startDate: {required},
    endDate: {required},
    reportConfigId: {required}
  },
  computed: {
    totalPrice () {
      return this.groups.reduce((t,v) => t += +v.totalPrice, 0)
    }
  },
  methods: {
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()
      this.groups = []
      this.$apollo.query({
        query: LIST_REPORT_INVENTORY_BY_INTERVAL(this.templateType),
        variables: {
          docType: this.docType,
          startDate: this.$date.format(this.startDate).utc,
          endDate: this.$date.format(this.endDate, 0, 1).utc,
          contactId: this.contactId,
          reportConfigId: this.reportConfigId,
          search: this.search
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.groups = res.data.groups.map(g => {
          return {
            id: g.id,
            order: g.order,
            name: g.name,
            regexExp: g.regexExp,
            totalPrice: g.totalPrice,
            inventories: g.inventories,
            isDetail: false,
          }
        })
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    qtyByGroup (arr) {
      return arr.reduce((t,v) => {
        if (!t[v.unit]) t[v.unit] = 0
        t[v.unit] += +v.qty
        return t
      }, {})
    }
  },
  components: {
    ListReportInventoryContactInput,
    ListReportInventoryReportConfigInput
  }
}
</script>

<style lang="css" scoped>
</style>
